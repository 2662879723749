<template>
  <div class="box">
    <div class="header">
      <div class="whhh">
        <div class="header_img">
          <img
            @click="dialogVisible = true"
            class="header_imgs"
            src="../../assets/peixun.png"
            alt=""
          />
        </div>
        <!-- <img
          class="headerimg"
          src="../../assets/peixunzixun_banner@2x.png"
          alt=""
        /> -->
      </div>
      <div style="height: 530px"></div>
    </div>
    <div class="mk">
      <div class="w10">
        <div class="tablist">
          <div class="item active">资讯一览</div>
        </div>
        <div class="contentlist" v-if="list.length > 0">
          <div
            v-for="(item, index) in list"
            :key="index"
            class="items"
            @click="go(item)"
          >
            <img
              :src="
                'https://bes.zpton.com' + item.cover ||
                '../../assets/shouye_banner1.jpg'
              "
              alt=""
            />
            <div style="margin-left: 34px">
              <div class="item_1">
                {{ item.title }}
              </div>
              <div class="item_2"></div>
              <div class="item_3">{{ item.time }}</div>
            </div>
          </div>

          <el-pagination
            class="block"
            @current-change="handleCurrentChange"
            :current-page="currentPage4"
            :page-size="from.pageSize"
            background
            layout="total, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>

          <div style="height: 50px"></div>
        </div>
        <div v-else class="tac">—— 暂无案例 ——</div>
      </div>
    </div>
    <el-dialog
      top="45vh"
      title="立即咨询"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
      center
    >
      <div style="text-align: center">
        <img
          class="dialog_img"
          src="../../assets/kefu_erweima_imgs.jpg"
          alt=""
        />
      </div>
      <div style="text-align: center; margin-top: 20px; font-size: 16px">
        400-100-2925
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      dialogVisible: false,
      list: [],
      active: 1,
      currentPage4: 1,
      from: {
        page: 1,
        pageSize: 5,
        classify_ids: "1", //分类id
      },
      total: 10,
    };
  },
  methods: {
    popup() {
      window.location.href =
        "https://www.zpton.com/allcourses?type=1&name=%E8%99%8E%E5%B9%B4%E5%B0%B1%E5%9C%B0%E8%BF%87%C2%B7%E6%8A%80%E8%83%BD%E5%85%8D%E8%B4%B9%E5%AD%A6&id=234&is_type=2";
    },
    ...mapActions(["getNews1", "getNews"]),
    // 查看资讯
    go(item) {
      this.$router.push({
        path: "/informationdetail",
        query: {
          id: item.id,
        },
      });
    },
    cut(val) {
      this.active = val;
      this.getNews({
        classify_ids: val, //分类id
      }).then((res) => {
        if (res.successCode == 200) {
          this.list = res.responseBody.list;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    //分页
    handleCurrentChange(val) {
      this.from.page = val;
      this.search();
    },
    // 列表数据
    search() {
      this.getNews(this.from).then((res) => {
        if (res.successCode == 200) {
          this.list = res.responseBody.list;
          this.total = res.responseBody.total;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
  mounted() {
    window.scrollTo(0, 0);
    this.search();
  },
};
</script>

<style lang="scss" scoped>
.box {
  overflow: hidden;
  position: relative;
}
.header_imgs {
  width: 1200px;
  display: block;
  margin: 0 auto;
  margin-top: 50px;
}

.tablist {
  margin: 110px 0 50px 0;
  display: flex;
  justify-content: space-around;
  line-height: 80px;
  width: 1000px;
  height: 80px;
  background: #ffffff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  .item {
    position: relative;
    font-size: 18px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #222222;
  }
  .active {
    &::before {
      position: absolute;
      content: "";
      width: 57px;
      height: 4px;
      background: #1276fb;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
.mk {
  overflow: hidden;
  background: url("../../assets/beijingtu.png");
}
.contentlist {
  margin-top: 50px;
  .items {
    cursor: pointer;
    position: relative;
    padding: 24px 27px;
    box-sizing: border-box;
    display: flex;
    margin-bottom: 55px;
    width: 1000px;
    height: 211px;
    background: #ffffff;

    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    img {
      width: 242px;
      height: 165px;
      background: #ececec;
      border-radius: 10px;
    }
    .item_1 {
      margin-bottom: 15px;
      font-size: 18px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #222222;
    }
    .item_2 {
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #a5a5a5;
    }
    .item_3 {
      position: absolute;
      left: 303px;
      top: 165px;
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #a5a5a5;
    }
  }
}
.block {
  display: block;
  margin: 0 auto;
}
.tac {
  text-align: center;
  margin: 200px 0;
}
</style>
